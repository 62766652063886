// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-add-js": () => import("./../../../src/pages/add.js" /* webpackChunkName: "component---src-pages-add-js" */),
  "component---src-pages-channels-js": () => import("./../../../src/pages/channels.js" /* webpackChunkName: "component---src-pages-channels-js" */),
  "component---src-pages-discogs-js": () => import("./../../../src/pages/discogs.js" /* webpackChunkName: "component---src-pages-discogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labels-js": () => import("./../../../src/pages/labels.js" /* webpackChunkName: "component---src-pages-labels-js" */),
  "component---src-pages-logs-js": () => import("./../../../src/pages/logs.js" /* webpackChunkName: "component---src-pages-logs-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */)
}

